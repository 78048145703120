import * as React from 'react';

// material-ui
import {
    CardContent,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    TextField
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { useDispatch } from 'store';

// assets
import SearchIcon from '@mui/icons-material/Search';
import axiosServices from 'utils/axios';
import { fetchMerchants } from 'utils/api';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import ConfirmationModal from 'ui-component/Modal';
import useAuth from 'hooks/useAuth';
import { ROLE_SUPERADMIN } from 'constant';
import TableHeader from 'ui-component/TableHead';
import Loader from 'ui-component/Loader';

// table header options
const headCells = [
    {
        id: 'merchantId',
        numeric: true,
        label: 'MID',
        align: 'center'
    },
    {
        id: 'name',
        numeric: false,
        label: 'Name',
        align: 'center'
    },
    {
        id: 'status',
        numeric: true,
        label: 'Status',
        align: 'center'
    }
];

// ==============================|| MERCHANT PAYIN ||============================== //

const PayinClient = () => {
    const dispatch = useDispatch();
    const { user } = useAuth();

    // show a right sidebar when clicked on new product
    const [resetOpen, setResetOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [search, setSearch] = React.useState('');
    const [rows, setRows] = React.useState([]);
    const [allRows, setAllRows] = React.useState([]);

    const [copy, setCopy] = React.useState(false);

    React.useEffect(() => {
        if (copy) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Copied!',
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            dispatch(closeSnackbar);
            setCopy(false);
        }
    }, [copy]);

    const fetchDataFromAPI = async () => {
        setLoading(true);
        try {
            const result = await fetchMerchants();
            setLoading(false);
            setRows(result);
            setAllRows(result);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    React.useEffect(() => {
        fetchDataFromAPI();
    }, []);

    const handleSearch = (event) => {
        const newString = event?.target.value;
        setSearch(newString || '');

        if (newString) {
            const newRows = rows.filter((row) => {
                let matches = true;

                const properties = ['merchantId', 'name'];
                let containsQuery = false;

                properties.forEach((property) => {
                    if (row[property].toString().toLowerCase().includes(newString.toString().toLowerCase())) {
                        containsQuery = true;
                    }
                });

                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
            setRows(newRows);
        } else {
            setRows(allRows);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    const handleStatus = () => {
        setLoading(true);
        axiosServices
            .put(`updateMerchantWithLabel`, {
                merchantId: resetOpen?.merchantId,
                label: 'label_isPayInEnable',
                value: resetOpen?.isPayInEnable ? 'false' : 'true'
            })
            .then((r) => {
                setLoading(false);
                setResetOpen(false);
                fetchDataFromAPI();
            })

            .catch((e) => {
                setLoading(false);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: e?.message || 'Error while updating status',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
                dispatch(closeSnackbar);
            });
    };

    return (
        <MainCard title="Payin Client" content={false}>
            {loading && <Loader />}
            <CardContent>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                )
                            }}
                            onChange={handleSearch}
                            placeholder="Search"
                            value={search}
                            size="small"
                        />
                    </Grid>
                </Grid>
            </CardContent>

            {/* table */}
            <TableContainer>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHeader columns={headCells} />
                    <TableBody>
                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                            /** Make sure no display bugs if row isn't an OrderData object */
                            if (typeof row === 'number') return null;
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <TableRow hover>
                                    <TableCell sx={{ width: 200 }} align="center" component="th" id={labelId} scope="row">
                                        {row.merchantId}
                                    </TableCell>
                                    <TableCell align="center" component="th" id={labelId} scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Stack
                                            onClick={() => setResetOpen(row)}
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <IconButton color="primary" size="small">
                                                <Switch checked={row.isPayInEnable} />
                                                {/* {row.isPayInEnable.toString()} */}
                                            </IconButton>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            {user?.role === ROLE_SUPERADMIN && (
                <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                    <ConfirmationModal
                        loading={loading}
                        open={resetOpen}
                        title={`${resetOpen?.isPayInEnable ? 'Deactivate' : 'Activate'}`}
                        subTitle={`Are you sure, you want to ${resetOpen?.isPayInEnable ? 'deactive' : 'active'} payin`}
                        handleClose={() => setResetOpen(false)}
                        handleSubmit={handleStatus}
                    />
                </Grid>
            )}

            {/* table pagination */}
            <TablePagination
                rowsPerPageOptions={[50, 100, 200]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </MainCard>
    );
};

export default PayinClient;
