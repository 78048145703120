import axios from 'utils/axios';

export const fetchMerchants = async () => {
    const response = await axios.get('getMerchants?pageIndex=0&pageSize=200');
    return response.data.data.merchantList;
};

export const fetchPayoutControl = async () => {
    const response = await axios.get('get/payout/control/feature?pageIndex=0&pageSize=200');
    return response.data.data?.payoutControlFeatureList;
};

export const fetchActiveMerchants = async () => {
    const response = await axios.get('getActiveMerchants');
    return response.data.data;
};

export const fetchAllRemarks = async () => {
    const response = await axios.get('getAllRemark');
    return response.data.data;
};

export const getAllLoggedInMerchants = async () => {
    const response = await axios.get('getAllLoggedInMerchants?pageIndex=0&pageSize=200');
    return response.data.data?.transactionList;
};

export const getAllBanksName = async () => {
    const response = await axios.get('getBankNamesList');
    return response.data.data.banks;
};

export const fetchBankAmount = async (startDate, endDate) => {
    const response = await axios.get(`getAmountBankWise?startDate=${startDate}&endDate=${endDate}`);
    return response?.data?.data;
};

export const fetchMerchSummary = async (startDate, endDate) => {
    const response = await axios.get(`merchant/summary?startDate=${startDate}&endDate=${endDate}&page=0&size=100`);
    return response?.data?.data?.merchantList;
};

export const getActiveBanks = async () => {
    const response = await axios.get('getActiveBanks');
    return response.data.data.banks;
};

export const getActiveDebitBanks = async () => {
    const response = await axios.get('getActiveDebitBanks');
    return response.data.data.banks;
};

export const fetchRoles = async () => {
    const response = await axios.get('getRoles');
    return response.data;
};

export const fetchPGNames = async () => {
    const response = await axios.get('getPgNames');
    return response.data?.data;
};

export const fetchUsers = async () => {
    const response = await axios.get('getAllUsers?pageIndex=0&pageSize=500');
    return response.data;
};

export const fetchMerchantUsers = async () => {
    const response = await axios.get('getAllMerchantUsers?pageIndex=0&pageSize=500');
    return response.data?.data;
};

export const fetchUsersPassword = async (type) => {
    const response = await axios.get(`pagination/getAllUsers/forceUpdateUser?pageIndex=0&pageSize=1000&userType=${type}`);
    return response.data?.data?.userList || [];
};

export const fetchAllUPIApps = async () => {
    const response = await axios.get('getAllUpiApp');
    return response.data?.data;
};

export const getLogs = async (payload) => {
    const response = await axios.get('logs', {
        params: payload
    });
    return response.data;
};

export const fetchMerchantDetails = async (id) => {
    const response = await axios.get(`getMerchant/${id}`);
    return response.data.data;
};

export const fetchMerchantBanks = async (id) => {
    const response = await axios.get(`get/bank/merchant/${id}`);
    return response.data;
};

export const fetchMerchantMeta = async (id) => {
    const response = await axios.get(`/get/in/payin/merchant/${id}`);
    return response.data?.data;
};

export const fetchPayinMeta = async () => {
    const response = await axios.get(`/get/in/payin`);
    return response.data?.data || [];
};

export const fetchMerchantPayout = async (id) => {
    const response = await axios.get(`get/payout/merchant/${id}`);
    return response.data;
};

export const fetchIPData = async (id) => {
    const response = await axios.get(`getMerchantWhiteListedIps/${id}`);
    return response.data;
};

export const fetchMerchantAffiliates = async () => {
    const response = await axios.get(`getAllAffiliateMerchant`);
    return response.data.data?.agentList || [];
};

export const fetchMerchantAffiliatesById = async (id) => {
    const response = await axios.get(`getAffiliateMerchantByAffiliateId/${id}`);
    return response.data.data?.agentList || [];
};

export const fetchTransactions = async (page, size, startDate, endDate) => {
    const response = await axios.get(`getTransactions?pageIndex=${page}&pageSize=${size}&startDate=${startDate}&endDate=${endDate}`);
    return response.data;
};

export const fetchSsTransactions = async (page, size, startDate, endDate) => {
    const response = await axios.get(`getSSTxns?pageIndex=${page}&pageSize=${size}&startDate=${startDate}&endDate=${endDate}`);
    return response.data;
};

export const fetchPayoutTransactions = async (page, size, startDate, endDate) => {
    const response = await axios.get(`getPayoutTransactions?pageIndex=${page}&pageSize=${size}&startDate=${startDate}&endDate=${endDate}`);
    return response.data;
};

export const fetchPayoutTransactionsByFilter = async (payload, page, size) => {
    const response = await axios.post(`newPayOutFilter?pageIndex=${page}&pageSize=${size}`, payload);
    return response.data;
};

export const fetchSsTransactionsById = async (id, payId) => {
    const response = await axios.get(`getSSTxn/${payId}/${id}`);
    return response.data;
};

export const fetchBankTransactions = async (page, size, startDate, endDate) => {
    const response = await axios.get(`getbanktransaction?pageIndex=${page}&pageSize=${size}&startDate=${startDate}&endDate=${endDate}`);
    return response.data;
};

export const fetchBankTransactionsByID = async (id, page, size) => {
    const response = await axios.get(`getbanktransaction/${id}?pageIndex=${page}&pageSize=${size}`);
    return response.data;
};

export const fetchPayoutDetailsByPG = async (id) => {
    const response = await axios.get(`getAllPayoutMeta/${id}`);
    return response.data;
};

export const fetchPayInDetailsByID = async (id) => {
    const response = await axios.get(`getAllPayinMeta/${id}`);
    return response.data;
};

export const fetchTotalCollection = async () => {
    const response = await axios.get(`getTotalCollecton`);
    return response.data;
};

export const fetchPGSummary = async (id) => {
    const response = await axios.get(`metaDetailsWithPgLabelName/${id}`);
    return response.data;
};

export const fetchPayinSummary = async (merchant, startDate, endDate) => {
    const payload = {
        merchantId: merchant,
        startDate,
        endDate
    };
    const response = await axios.get(`getTotalPayinAmount`, {
        params: payload
    });
    return response.data;
};

export const fetchPayoutSummary1 = async (startDate, endDate) => {
    const payload = {
        startDate,
        endDate
    };
    const response = await axios.get(`meta/transaction/summary/count`, {
        params: payload
    });
    return response.data;
};

export const fetchPayoutBalance = async () => {
    const response = await axios.get(`meta/transaction/summary/balance`);
    return response.data;
};

export const fetchPayoutMappingAccounts = async () => {
    const response = await axios.get(`meta/mapping/accountId/list`);
    return response.data;
};

export const fetchPayoutSummary = async (merchant, startDate, endDate) => {
    const payload = {
        merchantId: merchant,
        startDate,
        endDate
    };
    const response = await axios.get(`getTotalPayoutAmount`, {
        params: payload
    });
    return response.data;
};

export const fetchDashboardData = async () => {
    const response = await axios.get(`getChartData`);
    return response.data?.data || [];
};

export const fetchDashboardDataPayout = async () => {
    const response = await axios.get(`getChartDataPayout`);
    return response.data?.data || [];
};

export const fetchRemarks = async () => {
    const response = await axios.get(`getRemarks`);
    return response.data;
};

export const fetchSSTByFilter = async (payload) => {
    const response = await axios.get(`getSSTxnsByFilter`, {
        params: payload
    });
    return response.data;
};

export const fetchSSByFilterNew = async (payload, page, size) => {
    const response = await axios.post(`new/filter/ssTransactions?pageIndex=${page}&pageSize=${size}`, payload);
    return response.data;
};

export const fetchBankTByFilter = async (payload, page, size) => {
    const response = await axios.post(`newBankTxnFilter?pageIndex=${page}&pageSize=${size}`, payload);
    return response.data;
};

export const fetchBankTByFilterOld = async (payload) => {
    const response = await axios.get(`getBankTransactionByFilter`, {
        params: payload
    });
    return response.data;
};

export const fetchSupportLogsByFilter = async (payload) => {
    const response = await axios.get(`supportLogFilter`, {
        params: payload
    });
    return response.data;
};

export const getReports = async (page, size, startDate, endDate) => {
    const response = await axios.get(`getReport?page=${page}&pageSize=${size}&startDate=${startDate}&endDate=${endDate}`);
    return response.data;
};

export const getDebitBankTxn = async (bankId, page, size, startDate, endDate) => {
    const response = await axios.get(
        `bankTransation/debitBank/${bankId}?pageIndex=${page}&pageSize=${size}&startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
};

export const getWebhooks = async (page, size, payload) => {
    const response = await axios.post(`newWebHookFilter?pageIndex=${page}&pageSize=${size}`, payload);
    return response.data;
};

export const getCustomerOrderData = async (page, size, payload) => {
    const response = await axios.post(`getCustomerFilterData?pageIndex=${page}&pageSize=${size}`, payload);
    return response.data?.data || [];
};

export const fetchMerchConfigData = async (page, size) => {
    const response = await axios.get(`getAllConfigMerchant?page=${page}&pageSize=${size}`);
    return response.data?.data?.merchantConfigList || [];
};

export const fetchMerchVpnData = async (page, size) => {
    const response = await axios.get(`get/merchantVpnConfig?page=${page}&pageSize=${size}`);
    return response.data?.data?.merchantVpnConfigurationList || [];
};

export const fetchMerchIntentConfigData = async (page, size) => {
    const response = await axios.get(`payment/process/time/getAllConfigMerchant?page=${page}&pageSize=${size}`);
    return response.data?.data?.merchantConfigList || [];
};

export const fetchClickPayConfigData = async (page, size) => {
    const response = await axios.get(`get/click/pay/config?page=${page}&pageSize=${size}`);
    return response.data || [];
};

export const fetchMerchMetaConfigData = async (page, size) => {
    const response = await axios.get(`getAllMerchantProfileMeta?page=${page}&pageSize=${size}`);
    return response.data || [];
};

export const getDebitBanks = async (page, size) => {
    const response = await axios.get(`getDebitBankAccounts?pageIndex=${page}&pageSize=${size}`);
    return response.data?.data?.BankList || [];
};

export const fetchPG = async () => {
    const response = await axios.get('getAllPgMenu');
    return response.data?.data || [];
};

export const fetchPayout = async () => {
    const response = await axios.get('get/payout');
    return response.data?.data || [];
};

export const fetchSettlement = async (page, size, startDate, endDate) => {
    const response = await axios.get(
        `getAllSettleTransaction?pageIndex=${page}&pageSize=${size}&startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
};

export const fetchSettlementByMerchant = async (id, page, size, startDate, endDate) => {
    const response = await axios.get(
        `getAllSettleTransaction/${id}?pageIndex=${page}&pageSize=${size}&startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
};

export const fetchPayoutCredit = async (id, page, size, startDate, endDate) => {
    const response = await axios.get(
        `payout/getAllManualPayoutCredit/?pageIndex=${page}&pageSize=${size}&startDate=${startDate}&endDate=${endDate}&merchantId=${
            id || ''
        }`
    );
    return response.data;
};

export const fetchStatsByMerchant = async (id) => {
    const response = await axios.get(`getDashboardForMerchant/${id}`);
    return response.data;
};

export const fetchProcessingSettlementRequests = async () => {
    const response = await axios.get(`settleRequest?pageIndex=${0}&pageSize=${100}&statusRequired=true`);
    return response.data;
};

export const fetchSettlementRequests = async (page, size, payload) => {
    const response = await axios.post(`newMerchantSettlementFilter?pageIndex=${page}&pageSize=${size}`, payload);
    return response.data;
};

export const fetchAffiliateProcessingSettlementRequests = async () => {
    const response = await axios.get(`affiliateSettleRequest?pageIndex=${0}&pageSize=${100}&statusRequired=true`);
    return response.data;
};

export const fetchAffiliateSettlementRequests = async (page, size, payload) => {
    const response = await axios.post(`newAffiliateFilter?pageIndex=${page}&pageSize=${size}`, payload);
    return response.data;
};

export const fetchPayoutRequests = async (page, size, payload) => {
    const response = await axios.post(`newMerchantPayOutRequestFilter?pageIndex=${page}&pageSize=${size}`, payload);
    return response.data;
};

export const fetchProcessingPayoutRequests = async () => {
    const response = await axios.get(`payout/getAllPayoutRequest?pageIndex=${0}&pageSize=${100}&statusRequired=true`);
    return response.data;
};

export const fetchProxyData = async (page, size) => {
    const response = await axios.get(`getPayoutProxyWhiteListedIps?page=${page}&pageSize=${size}`);
    return response.data?.data;
};

export const fetchURLData = async (page, size) => {
    const response = await axios.get(`getCheckOutUrls?page=${page}&pageSize=${size}`);
    return response.data?.data;
};

export const fetchLocData = async (page, size) => {
    const response = await axios.get(`get/all/address/tracker?page=${page}&pageSize=${size}`);
    return response.data?.data?.addressTrackers;
};

export const getPayoutBanks = async (page, size) => {
    const response = await axios.get(`getPayoutBankAccounts?pageIndex=${page}&pageSize=${size}`);
    return response.data?.data?.BankList || [];
};

export const getActivePayoutBanks = async () => {
    const response = await axios.get('getPayoutActiveBanks');
    return response.data?.data?.banks || [];
};

export const fetchPayoutTxn = async (id) => {
    const response = await axios.get(`getPayoutTxn/${id}`);
    return response.data;
};

export const getFileReports = async (page, size, startDate, endDate) => {
    const response = await axios.get(
        `getPayoutProcessedFileReport?page=${page}&pageSize=${size}&startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
};

export const getBankTxnFileReports = async (page, size, startDate, endDate) => {
    const response = await axios.get(
        `getCreateBankTxnProcessedfileReport?page=${page}&pageSize=${size}&startDate=${startDate}&endDate=${endDate}`
    );
    return response.data;
};
