import React, { useEffect, useState } from 'react';

// material-ui
import {
    Button,
    Grid,
    CardContent,
    Autocomplete,
    TextField,
    MenuItem,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Dialog,
    Typography
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import { fetchMerchants, fetchIPData } from 'utils/api';
import Loader from 'ui-component/Loader';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import axiosServices from 'utils/axios';
import { useDispatch } from 'react-redux';
import ConfirmationModal from 'ui-component/Modal';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyAll } from '@mui/icons-material';

const BulkWebhook = () => {
    const dispatch = useDispatch();

    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState();
    const [open, setOpen] = React.useState(false);

    const [actions, setActions] = useState([
        {
            id: 1,
            label: 'Payin ID Webhook'
        },
        {
            id: 2,
            label: 'Payout ID Webhook'
        },
        {
            id: 3,
            label: 'Payout Manual Status'
        },
        {
            id: 4,
            label: 'Payfast Status'
        }
    ]);
    const [selectedAction, setSelectedAction] = useState();

    const handleCloseDialog = () => {
        setOpen(false);
    };

    const handleGenerate = () => {
        if (!selectedAction) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'please select action',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        } else {
            setLoading(true);

            axiosServices
                .post(
                    `${
                        selectedAction?.id === 1
                            ? `initiateWebHookUsingTxnIds`
                            : selectedAction.id === 2
                            ? `initiateWebHookForMultipleIds`
                            : selectedAction.id === 4
                            ? '/payfast/payment/payin/status/bulk'
                            : 'hitCallStatusManuallyByIds'
                    }`,
                    {
                        ids: text
                    }
                )
                .then((res) => {
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: res?.data?.message || 'Request processed',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: true
                        })
                    );
                    setOpen(false);
                    setText('');
                    setLoading(false);
                })
                .catch((e) => {
                    setLoading(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: e?.message || 'Error while generating secret',
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: true
                        })
                    );
                    dispatch(closeSnackbar);
                });
        }
    };

    return (
        <MainCard
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>Bulk Webhook</div>
                </div>
            }
        >
            {loading && <Loader />}
            <CardContent>
                <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                        <Autocomplete
                            id="action-select"
                            options={actions || []}
                            onChange={(e, value) => {
                                setSelectedAction(value);
                            }}
                            sx={{ width: 200 }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    onChange={(e, value) => {
                                        setSelectedAction(value);
                                    }}
                                    label="Action"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid>
                    <TextField value={text} onChange={(e) => setText(e.target.value)} fullWidth sx={{ mt: 4 }} multiline rows={10} />
                </Grid>
                <Button
                    disabled={!selectedAction || !text?.trim()}
                    sx={{ mt: 4 }}
                    variant="contained"
                    size="small"
                    onClick={() => setOpen(true)}
                >
                    Submit
                </Button>
                <Dialog
                    open={open}
                    keepMounted
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-slide-title1"
                    aria-describedby="alert-dialog-slide-description1"
                >
                    {open && (
                        <>
                            <DialogTitle id="alert-dialog-slide-title1">Submit</DialogTitle>
                            <DialogContent style={{ display: 'flex', alignItems: 'center' }}>
                                <DialogContentText id="alert-dialog-slide-description1">Are you sure, you want to submit</DialogContentText>
                            </DialogContent>
                            <DialogActions sx={{ pr: 2.5 }}>
                                <Button disabled={loading} variant="contained" size="small" onClick={handleGenerate}>
                                    Yes
                                </Button>
                            </DialogActions>
                        </>
                    )}
                </Dialog>
            </CardContent>
        </MainCard>
    );
};

export default BulkWebhook;
